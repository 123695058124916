import { Component, OnInit, Inject } from "@angular/core";
import { ExportService } from "src/app/services/export/export.service";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
import { md5 } from "./md5";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RutService } from "rut-chileno";

@Component({
  selector: "app-curso",
  templateUrl: "./curso.component.html",
  styleUrls: ["./curso.component.scss"],
})
export class CursoComponent implements OnInit {
  ck0;
  ck1;
  ck2;
  ck3;
  ck4;
  ck5;
  pagina;
  modulo;
  panelOpenState = false;
  ultPaginaMod0 = 1;
  ultPaginaMod1 = 5;
  ultPaginaMod2 = 1;
  ultPaginaMod3 = 1;
  introduccion = ["Informacion Empresa"];
  tipoUsuario;

  modulo1 = [
    "Introducción",
    "Marco Legal Del Sistema De Gestión De La Calidad E Inocuidad Alimentaria Del Programa De Alimentación Escolar",
    "Rol Del Manipulador De Alimentos",
    "Funciones Y Recomendaciones Asociadas Al Personal Manipulador De Alimentos",
    "Higiene Y Seguridad, Elementos De Protección Personal",
    "Medio Ambiente",
    "Prevención De Riesgos Laborales",
    "Ley De Accidentes Laborales N°16.744",
    "Prevención De Riesgos Laborales",
    "Uso Y Manejo Del Extintor",
    "Evaluación",
  ];

  modulo2 = [
    "Caracteristicas generales de principales patógenos",
    "Forma mas común de la llegada del patógeno al alimento",
    "ETAs",
    "Microorganismos de importancia en la salud humana",
    "Peligros de inocuidad alimentaria",
    "Formas de presentación de peligros en los alimentos",
    "Factores que favorecen el crecimiento de los microorganismos",
    "Contaminación cruzada",
    `Identificar peligros en flujo de procesos: <br>
  Transporte, recepción, almacenamiento, 
  Operaciones preliminares, operaciones definitivas,
  Entrega de raciones  a temperaturas seguras antes y después del servicio `,
    "Medidas de control de peligros alimentarios",
    "Evaluación",
  ];

  modulo3 = [
    "Los documentos son esenciales para un sistema de gestión de calidad y seguridad alimentaria",
    "Procedimientos operativos de sanitización (POES)",
    "Higiene personal",
    "Lavado de manos",
    "POES limpieza y sanitización",
    "Actividad 1 - dosificación limpieza y sanitización JUNAEB",
    "Tabla de frecuencia de limpieza y sanitización",
    "POES limpieza y sanitización",
    "POES limpieza y sanitización de contenedores de basura",
    "POES manejo de productos químicos",
    "POES manejo y control de plagas",
    "POES control y seguridad del agua",
    "POES prevención contaminación cruzada",
    "Procedimientos operativos estandarizados (POE)",
    "Conceptos asociados",
    "Flujo operacional",
    "POE recepción alimentos perecibles y no perecibles",
    "Producto no conforme o potencialmente no inocuo",
    "Toma temperatura recepción refrigerados y congelados",
    "Actividad 2 - límite crítico T ° recepción refrigerados",
    "Acciones correctivas de temperaturas en recepción",
    "Actividad 3 - acciones correctivas recepción congelados",
    "Actividad 4 - control de calidad en recepción",
    "Registro /monitoreo /PCC/ punto crítico de control video registro EST 02",
    "Flujo operacional",
    "POE almacenamiento alimentos no perecible pasos operacionales",
    "POE almacenamiento alimentos perecible pasos operacionales",
    "POE control de temperaturas en equipo de frío pasos operacionales",
    "Acciones correctivas equipos de frío",
    "Flujo operacional",
    "Pre-elaboración de materias primas",
    "POE mantención 1",
    "POE cocción y mantención",
    "Límite crítico temperatura PCC 5 PCC 6",
    "Toma de temperatura en preparaciones",
    "Control de procesos cocción",
    "Actividad 5 - acciones correctivas",
    "Flujo operacional",
    "Evaluación"
  ];

  modulo4 = [
    "Conceptos básicos", 
    "Glosario técnico",
    "Uso del cuchillo y tipos de corte",
    "Evaluación sensorial de las preparaciones",
    "Consideraciones por zona geográfica",
    "Diferencias de estructura alimentaria transición- básica- media",
    "Preparaciones que no se deben entregar por no cumplir con el estándar JUNAEB",
    "Control de productos",
    "Calidad de los alimentos y materias primas C3",
    "Control de ración servida C4",
    "Evaluación"
  ];  

  preguntaIncompleta = [];
  opcionFinal;
  rutPersona = "";
  nombrePersona = "";

  constructor(
    private exportService: ExportService,
    private trackingService: TrackingService,
    private cryptoServ: CryptoservService,
    public dialog: MatDialog,
    private rutService: RutService
  ) {}

  async hola() {
    //codigo creado para borrar los registros
    //console.log("hola");
    //this.trackingService.holaTracking();
  }

  ngOnInit(): void {
    const tempRut = localStorage.getItem("rut");
    this.rutPersona = this.cryptoServ.decrypted(tempRut);
    this.rutPersona = this.rutService.rutFormat(this.rutPersona);
    const usuario = localStorage.getItem("usuario");
    const aux = JSON.parse(usuario);
    this.nombrePersona = this.cryptoServ.decrypted(aux.name);
    this.tipoUsuario =this.cryptoServ.decrypted(aux.tipoUsuario);
    console.log(aux)
    console.log(this.tipoUsuario)

    //this.hola();
    this.ck0 = false;
    this.ck1 = false;
    this.ck2 = false;
    this.ck3 = false;
    this.ck4 = false;
    const tempPagina = localStorage.getItem("pagina");
    this.pagina = this.cryptoServ.decrypted(tempPagina);
    this.pagina = parseInt(this.pagina);
    //this.pagina = parseInt(localStorage.getItem('pagina'));
    const tempModulo = localStorage.getItem("modulo");
    this.modulo = this.cryptoServ.decrypted(tempModulo);
    this.modulo = parseInt(this.modulo);
    //this.modulo = parseInt(localStorage.getItem('modulo'));
    console.log(this.pagina)
    console.log(this.modulo)
    this.validActualizado();
    if (this.modulo == 5) {
      this.ck0 = this.ck1 = this.ck2 = this.ck3 = this.ck4 = true;
    } else if (this.modulo == 4) {
      this.ck0 = this.ck1 = this.ck2 = this.ck3 = true;
    }else if (this.modulo == 3) {
      this.ck0 = this.ck1 = this.ck2 = true;
    } else if (this.modulo == 2) {
      this.ck0 = this.ck1 = true;
    } else if (this.modulo == 1) {
      this.ck0 = true;
    }
  }

  async validActualizado() {
    let validEditLocal = false;
    const tempRut = localStorage.getItem("rut");
    const rutUser = this.cryptoServ.decrypted(tempRut);
    const obtenerInfo = await this.trackingService.getValidDbUser(rutUser);
    if (obtenerInfo) {
      const tempVModulo = localStorage.getItem("vmodulo");
      const temp2VModulo = this.cryptoServ.decrypted(tempVModulo);
      const vmodulo = parseInt(temp2VModulo);
      const tempVPagina = localStorage.getItem("vpagina");
      const temp2VPagina = this.cryptoServ.decrypted(tempVPagina);
      const vpagina = parseInt(temp2VPagina);
      if (this.modulo !== vmodulo) {
        if (this.modulo > vmodulo) {
          await this.updateInfoBd(rutUser, this.modulo, this.pagina);
        } else {
          this.updateInfoLocal(vmodulo, vpagina);
          validEditLocal = true;
        }
      } else if (this.modulo == vmodulo && this.pagina != vpagina) {
        if (this.pagina > vpagina) {
          await this.updateInfoBd(rutUser, this.modulo, this.pagina);
        } else {
          this.updateInfoLocal(vmodulo, vpagina);
          validEditLocal = true;
        }
      }
    }
    if (validEditLocal) {
      window.location.reload();
    }
  }

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }

  updateInfoLocal(modulo: number, pagina: number) {
    const encryptModulo = this.cryptoServ.encrypted(modulo);
    localStorage.setItem("modulo", encryptModulo);
    const encryptPagina = this.cryptoServ.encrypted(pagina);
    localStorage.setItem("pagina", encryptPagina);
  }

  isComple(mod) {
    if (mod == 5) {
      return this.ck5;
    } else if (mod == 4) {
      return this.ck4;
    } else if (mod == 3) {
      return this.ck3;
    } else if (mod == 2) {
      return this.ck2;
    } else if (mod == 1) {
      return this.ck1;
    } else {
      return this.ck0;
    }
  }

  isHabilitado(mod) {
    if (mod == 5) {
      if (this.ck4 || this.ck5) {
        return true;
      } else {
        return false;
      }
    } else if (mod == 4) {
      if (this.ck3 || this.ck4) {
        return true;
      } else {
        return false;
      }
    } else if (mod == 3) {
      if (this.ck2 || this.ck3) {
        return true;
      } else {
        return false;
      }
    } else if (mod == 2) {
      if (this.ck1 || this.ck2) {
        return true;
      } else {
        return false;
      }
    } else if (mod == 1) {
      if (this.ck0 || this.ck1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isValidPagina(pag) {
    if (pag <= this.pagina) {
      return true;
    } else {
      return false;
    }
  }

  setPaginasModulo0(pag) {
    this.ultPaginaMod0 = pag;
  }
  setPaginasModulo1(pag) {
    this.ultPaginaMod1 = pag;
  }
  setPaginasModulo2(pag) {
    this.ultPaginaMod2 = pag;
  }
  setPaginasModulo3(pag) {
    this.ultPaginaMod3 = pag;
  }

  saveLocaleModulo0() {
    var encrypt = this.cryptoServ.encrypted(this.ultPaginaMod0);
    localStorage.setItem("ultPaginaMod0", encrypt);
    //localStorage.setItem('ultPaginaMod0', JSON.stringify(this.ultPaginaMod0));
  }
  saveLocaleModulo1() {
    var encrypt = this.cryptoServ.encrypted(this.ultPaginaMod1);
    localStorage.setItem("ultPaginaMod1", encrypt);
    //localStorage.setItem('ultPaginaMod1', JSON.stringify(this.ultPaginaMod1));
  }
  saveLocaleModulo2() {
    var encrypt = this.cryptoServ.encrypted(this.ultPaginaMod2);
    localStorage.setItem("ultPaginaMod2", encrypt);
    //localStorage.setItem('ultPaginaMod2', JSON.stringify(this.ultPaginaMod2));
  }
  saveLocaleModulo3() {
    var encrypt = this.cryptoServ.encrypted(this.ultPaginaMod3);
    localStorage.setItem("ultPaginaMod3", encrypt);
    //localStorage.setItem('ultPaginaMod3', JSON.stringify(this.ultPaginaMod3));
  }

  async export() {
    var info = [];
    var orderExport = [];
    info = await this.trackingService.getAllExport();
    for (let i = 0; i < info.length; i++) {
      var dataProv = info[i].data();
      var rut = info[i].id.toString();
      rut = rut.replace(/ /g, "");
      rut = rut.trim();

      console.log(dataProv);

      orderExport.push({
        rut: rut,
        modulo: dataProv["modulo"],
        pagina: dataProv["pagina"],
        UltimaConexion: this.convertToUTC4String(dataProv["fecUpdate"]),
      });
    }
    this.exportService.exportExcel(
      orderExport,
      "CapacitacionManquehueTracking"
    );
  }

  convertToUTC4String(timestamp: any): string {
    // Crear una fecha a partir de los segundos
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    // Obtener el tiempo UTC
    const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
    // Convertir a UTC-4
    const utc4Time = new Date(utcTime - 4 * 3600000);
    // Crear un formateador para la fecha y la hora
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "America/Caracas", // Caracas es UTC-4
      timeZoneName: "short",
    };
    const formatter = new Intl.DateTimeFormat("es-ES", options);
    // Formatear la fecha
    const formattedDate = formatter.format(utc4Time);
    return formattedDate;
  }

  validAdmin() {
    var valid = false;
    var usuario = localStorage.getItem("usuario");
    const aux = JSON.parse(usuario);
    let perfil = this.cryptoServ.decrypted(aux.perfil);
    if (perfil == "0" || perfil == "1") {
      valid = true;
    }
    this.calculoCodAlumno();
    return valid;
  }

  validCursoCompleto() {
    var valid = false;
    //this.validPreguntaModulo();
    if (this.modulo >= 5) {
      if (this.pagina >= 0) {
        valid = true;
        //  this.setRespuestasFullTrue();
        //  this.opcionFinal = true;
        //} else if (this.opcionFinal) {
        //  valid = true;
      }
      //valid = true;
      //this.opcionFinal = true;
    }
    return valid;
  }


  calculoCodAlumno(): string {
    var valid = false;
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    var md5User = md5(rutUser);
    var md5Early = md5("EarlyAlicopsa2024Junio");
    if(this.tipoUsuario!='JUNAEB'){
      md5Early = md5("EarlyAlicopsa2024JunioJardin");
    }
    var oneGroup = md5User.substr(0, 5);
    var twoGroup = md5User.substr(md5User.length - 5, md5User.length - 1);
    var threeGroup = md5Early.substr(0, 5);
    var union = oneGroup + twoGroup + threeGroup;
    var preliminar = md5(union);
    var codigo = preliminar.substr(0, 8);
    
    return codigo;
  }

  setRespuestasFullTrue() {
    localStorage.setItem("m2-p1", "true");
    localStorage.setItem("m2-p2", "true");
    localStorage.setItem("m2-p3", "true");
    localStorage.setItem("m3-p1", "true");
    localStorage.setItem("m3-p2", "true");
    localStorage.setItem("m6-p1", "true");
    localStorage.setItem("m6-p2", "true");
    localStorage.setItem("m6-p3", "true");
    localStorage.setItem("m6-p4", "true");
    localStorage.setItem("m6-p5", "true");
    localStorage.setItem("m7-p1", "true");
    localStorage.setItem("m7-p2", "true");
  }

  validPreguntaModulo() {
    this.preguntaIncompleta = [];
    this.opcionFinal = true;
    // Inicio Validacion modulo 1
    if (
      localStorage.getItem("m1-p1") == "false" ||
      localStorage.getItem("m1-p1") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 1");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p2") == "false" ||
      localStorage.getItem("m1-p2") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 2");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p3") == "false" ||
      localStorage.getItem("m1-p3") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 3");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p4") == "false" ||
      localStorage.getItem("m1-p4") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 4");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p5") == "false" ||
      localStorage.getItem("m1-p5") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 5");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p6") == "false" ||
      localStorage.getItem("m1-p6") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 6");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p7") == "false" ||
      localStorage.getItem("m1-p7") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 7");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m1-p8") == "false" ||
      localStorage.getItem("m1-p8") == null
    ) {
      this.preguntaIncompleta.push("Módulo 1: Pregunta 8");
      this.opcionFinal = false;
    }
    // Fin Validacion modulo 1

    // Inicio Validacion modulo 2
    if (
      localStorage.getItem("m2-p1") == "false" ||
      localStorage.getItem("m2-p1") == null
    ) {
      this.preguntaIncompleta.push("Módulo 2: Pregunta 1");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m2-p2") == "false" ||
      localStorage.getItem("m2-p2") == null
    ) {
      this.preguntaIncompleta.push("Módulo 2: Pregunta 2");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m2-p3") == "false" ||
      localStorage.getItem("m2-p3") == null
    ) {
      this.preguntaIncompleta.push("Módulo 2: Pregunta 3");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m2-p4") == "false" ||
      localStorage.getItem("m2-p4") == null
    ) {
      this.preguntaIncompleta.push("Módulo 2: Pregunta 4");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m2-p5") == "false" ||
      localStorage.getItem("m2-p5") == null
    ) {
      this.preguntaIncompleta.push("Módulo 2: Pregunta 5");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m2-p6") == "false" ||
      localStorage.getItem("m2-p6") == null
    ) {
      this.preguntaIncompleta.push("Módulo 2: Pregunta 6");
      this.opcionFinal = false;
    }
    // fin Validacion modulo 2
    // inicio Validacion modulo 3
    if (
      localStorage.getItem("m3-p1") == "false" ||
      localStorage.getItem("m3-p1") == null
    ) {
      this.preguntaIncompleta.push("Módulo 3: Pregunta 1");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m3-p2") == "false" ||
      localStorage.getItem("m3-p2") == null
    ) {
      this.preguntaIncompleta.push("Módulo 3: Pregunta 2");
      this.opcionFinal = false;
    }

    if (
      localStorage.getItem("m3-p3") == "false" ||
      localStorage.getItem("m3-p3") == null
    ) {
      this.preguntaIncompleta.push("Módulo 3: Pregunta 3");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m3-p4") == "false" ||
      localStorage.getItem("m3-p4") == null
    ) {
      this.preguntaIncompleta.push("Módulo 3: Pregunta 4");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m3-p5") == "false" ||
      localStorage.getItem("m3-p5") == null
    ) {
      this.preguntaIncompleta.push("Módulo 3: Pregunta 5");
      this.opcionFinal = false;
    }
    if (
      localStorage.getItem("m3-p6") == "false" ||
      localStorage.getItem("m3-p6") == null
    ) {
      this.preguntaIncompleta.push("Módulo 3: Pregunta 6");
      this.opcionFinal = false;
    }
    // fin Validacion modulo 3

    if (this.modulo >= 5 && this.opcionFinal) {
      this.pagina = 1;
    }
  }

  openDialog() {
    this.dialog.open(CursoDialog, {
      data: this.calculoCodAlumno(),
    });
  }
}

@Component({
  selector: "app-curso",
  templateUrl: "./curso-dialog.html",
})
export class CursoDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  copyText(): void {
    var val = this.data;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }
}
