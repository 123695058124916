import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-alternative-question",
  templateUrl: "./alternativeQuestion.component.html",
  styleUrls: ["./alternativeQuestion.component.scss"],
})
export class AlternativeQuestionComponent implements OnInit{
  @Input() titulo: string = '';
  @Input() descripcion: string = '';
  @Input() opciones: string[] = [];
  @Input() choices: string[] = [];
  @Input() correctAnswer: number; // Agregar una variable para la respuesta correcta
  @Input() isRespondida: boolean;
  @Output() sendRespuesta: EventEmitter<any> = new EventEmitter<any>();

  alternativeChoiceForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.alternativeChoiceForm = this.fb.group({
      options: this.fb.array([]),
      selectedChoice: new FormControl('')
    });
  }

  ngOnInit(): void {
   // this.addCheckboxes();
    if(this.isRespondida){
      this.alternativeChoiceForm = this.fb.group({
        options: this.fb.array([]),
      selectedChoice: new FormControl('')
      });
    }
  }

  validRespondida(pos){
    if(this.isRespondida && pos == this.correctAnswer){
      return true;
    }
    return false;
  }

  // private addCheckboxes() {
  //   this.opciones.forEach(() => this.alternativeChoiceForm.controls.options.push(new FormControl(false)));
  // }

  opcSeleccionada(pos){
    console.log(pos);
    console.log(this.correctAnswer);
    if(pos == this.correctAnswer){
      this.sendRespuesta.emit(true);
    } else {
      this.sendRespuesta.emit(false);
    }
  }
}
