import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import Swal from "sweetalert2";

//Inicio Importa para variable de avanzar pregunta
import { Router } from "@angular/router";
import { TrackingService } from "src/app/services/tracking/tracking.service";
import { CryptoservService } from "src/app/services/cryposerv/cryptoserv.service";
import { PDFDocumentProxy } from "pdfjs-dist";
//Fin Importa para variable de avanzar pregunta

@Component({
  selector: "app-modulo3",
  templateUrl: "./modulo3.component.html",
  styleUrls: ["./modulo3.component.scss"],
})
export class Modulo3Component implements OnInit, AfterViewInit {
  //Inicio Variable para avanzar de pagina
  pagina: number;
  numberModulo: number = 3;
  //Fin Variable para avanzar de pagina
  sumaPage: number = 1;

  constructor(
    //Inicio declaracion de import para uno de avanzar pregunta
    private trackingService: TrackingService,
    private router: Router,
    private cryptoServ: CryptoservService,
    private renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef //Fin declaracion de import para uno de avanzar pregunta
  ) {}
  preguntas = [];
  respuesta = [];
  pathPdf = "../../../../assets/PDF/mod3Junaeb/";

  Pdf1 = this.pathPdf + "1-cap-mod3.pdf";
  Pdf2 = this.pathPdf + "2-cap-mod3.pdf";
  Pdf3 = this.pathPdf + "3-cap-mod3.pdf";
  Pdf4 = this.pathPdf + "4-cap-mod3.pdf";
  Pdf5 = this.pathPdf + "5-cap-mod3.pdf";
  Pdf6 = this.pathPdf + "6-cap-mod3.pdf";
  Pdf7 = this.pathPdf + "7-cap-mod3.pdf";
  Pdf8 = this.pathPdf + "8-cap-mod3.pdf";
  Pdf9 = this.pathPdf + "9-cap-mod3.pdf";
  Pdf10 = this.pathPdf + "10-cap-mod3.pdf";
  Pdf11 = this.pathPdf + "11-cap-mod3.pdf";

  img1 = this.pathPdf + "2-mod3_junaeb_tabla1.png";
  img2 = this.pathPdf + "6-mod3_junaeb_tabla2.png";
  img3 = this.pathPdf + "9-mod3_junaeb_tabla3.png";
  img4 = this.pathPdf + "16-mod3_junaeb_tabla4.png";
  img5 = this.pathPdf + "18-mod3_junaeb_tabla5.png";
  img6 = this.pathPdf + "20-mod3_junaeb_tabla6.png";

  page = [];
  totalPages: number = 0;
  cantPreg = 0;

  pdfActive: boolean[];
  imgActive: boolean[];
  preguntaActive: boolean[];

  //pregunta 1
  titulo1 = "Actividad 1 - dosificación limpieza y sanitización JUNAEB";
  descripcion1 = `En la  diapositiva  anterior  encuentra las  Tablas de dosificación de Lavaloza y Cloro para programas Junaeb para que realice el cálculo de 355  bandejas Elija la alternativa correcta de dosificación  y tiempo`;
  inputHtml1 = `
    <div class="border-2 text-center min-w-96">
            <img
            class="w-full"
            src="../../../../../assets/images/modulo3/tabla_modulo3_junaeb.png"
          />
        </div>
    `;

  opciones1: string[] = [
    "A es correcta",
    "B es correcta",
    "A y B es correcta",
    "C es correcta",
  ];
  correcta1 = 0;

  //pregunta 4
  titulo2 = "ACTIVIDAD 2 - CONTROL DE CALIDAD EN RECEPCIÓN ";
  descripcion2 = `
    Instrucciones: <br>  
Usted debe hacer la recepción de las materias primas 
en su establecimiento
De las prácticas detalladas a continuación cual o cuales son las correctas <br><br>
                              Elija la(s) alternativa(s) correcta (s)      

    `;
  opciones2: string[] = [
    "Fecha vencimiento: La fecha vencimiento esta vigente",
    "Los productos cumplen con la temperatura de rangos permitidos",
    "Estado de envase: El embalaje esta limpio, sin daños, íntegros y cubre completamente el producto",
    "Los empaques deben estar sellados sin presencia de humedad, sin elementos extraños",
    "Los productos recepcionados son dejados en la puerta de la Bodega y usted puede hacer la revisión después de 2 horas",
  ];
  choices2: string[] = ["1,2,4", "1,2,4,5", "1,2,3,4"];
  correcta2 = 2;

  //Pregunta 5
  titulo3: string = "";
  descripcion3: string = `
    <div class="px-4"> 
    Instrucciones:  <br><br>
En el Registro Elaboración PCC 5 y Mantención Preparaciones PCC6 existen criterios descritos para tomar la determinación de cual acción correctiva corresponde elegir en una situación ocurrida como en el siguiente caso:
<br>
En la entrega del servicio la toma de Temperatura De Mantención de la preparación está bajo 65,0 °C  por menos de 2 horas.
De las acciones detalladas a continuación:
<br><br>
 Escriba el número de acción correctiva que corresponda  y 
escriba el  número en la celda
</div>
`;
  opciones3: string[] = [
    "Calentar producto más tiempo y siga midiendo la temperatura hasta que la temperatura del centro térmico sea igual o mayor a 75,0°C",
    "Elimine el producto si no es posible seguir calentando por perdida de calidad organoléptica",
    "Las preparaciones cuya temperatura suba por encima de 5,0°C por más de 2 horas deben desecharse",
    "Si la desviación de temperatura es menor de 65,0°C por menos de 2 horas recalentar 1 vez hasta 75,0°C y servir",
    "Si la desviación de temperatura es menor de 65,0°C por más de 2 horas desechar.",
  ];
  correcta3: string =
    "Si la desviación de temperatura es menor de 65,0°C por menos de 2 horas recalentar 1 vez hasta 75,0°C y servir";

  //Pregunta 6
  titulo4 = "Evaluación del Módulo 3";
  descripcion4 = "Responda las preguntas y elija  V o F";
  preguntas4: any[] = [
    {
      text: "En relación a los Procedimientos Operativos Estandarizados de Sanitización son instrucciones escritas que tienen por objetivo establecer los pasos a seguir para prevenir la contaminación biológica, química y/o física de los alimentos ",
      value: true,
    },
    {
      text: "En la etapa operacional de Recepción, tenemos el PCC2,  que  corresponde al Control de T° en equipos de refrigeración y congelación.",
      value: false,
    },
    {
      text: "Para controlar el proceso de cocción la T° en el centro del producto debe ser como mínimo 75,0°C",
      value: true,
    },
    {
      text: "En el proceso de mantención  si la desviación de temperatura es menor de 65°C por menos de 2 horas recalentar 1 vez hasta 75°C y servir. ",
      value: true,
    },
    {
      text: "Siempre debo mezclar detergente y cloro, para obtener una mejor limpieza de vajilla, utensilios y limpieza y sanitización general.  ",
      value: false,
    },
    {
      text: "En el flujo de elaboración de alimentos contemplamos 5 Puntos Críticos de Control PCC.  ",
      value: true,
    },
    {
      text: "Los Registros son planillas de control  que prestan evidencia de que existe un control adecuado y deben ser llenados de forma correcta y sin inventarlos. ",
      value: true,
    },
  ];

  ngOnInit(): void {
    var paginaLink = +window.location.href.split("#")[1];
    var forPagina = "pagina" + paginaLink;
    console.log("paginaLink: " + paginaLink);
    var NumPag: number = paginaLink;
    console.log("num pagina: " + NumPag);
    this.validarAvancePagina(NumPag);
    this.paginarFull();
    //document.getElementById(forPagina).hidden = false;
  }

  ngAfterViewInit() {
    const pdfViewer = document.querySelector("pdf-viewer");

    if (pdfViewer) {
      const canvasWrapper = pdfViewer.querySelector(".canvasWrapper");
      const textLayer = pdfViewer.querySelector(".textLayer");

      if (canvasWrapper) {
        this.renderer.setStyle(canvasWrapper, "overflow", "auto");
      }

      if (textLayer) {
        this.renderer.setStyle(textLayer, "position", "relative");
      }

      // Asegúrate de que el visor del PDF se centra verticalmente
      this.renderer.setStyle(pdfViewer, "display", "flex");
      this.renderer.setStyle(pdfViewer, "flex-direction", "column");
      this.renderer.setStyle(pdfViewer, "justify-content", "center");
      this.renderer.setStyle(pdfViewer, "align-items", "center");
    }
  }

  validarAvancePagina(pagina) {
    switch (pagina) {
      case 2:
        this.sumaPage = 2;
        break;
      case 3:
        this.sumaPage = 4;
        break;
      case 4:
        this.sumaPage = 7;
        break;
      case 5:
        this.sumaPage = 11;
        break;
      case 6:
        this.sumaPage = 14;
        break;
      case 7:
        this.sumaPage = 15;
        break;
      case 8:
        this.sumaPage = 17;
        break;
      case 9:
        this.sumaPage = 20;
        break;
      case 10:
        this.sumaPage = 23;
        break;
      case 11:
        this.sumaPage = 25;
        break;
      case 12:
        this.sumaPage = 26;
        break;
      case 13:
        this.sumaPage = 27;
        break;
      case 14:
        this.sumaPage = 32;
        break;
      case 15:
        this.sumaPage = 33;
        break;
      case 16:
        this.sumaPage = 34;
        break;
      case 17:
        this.sumaPage = 36;
        break;
      case 18:
        this.sumaPage = 38;
        break;
      case 19:
        this.sumaPage = 41;
        break;
      case 20:
        this.sumaPage = 42;
        break;
      case 21:
        this.sumaPage = 43;
        break;
      case 22:
        this.sumaPage = 44;
        break;
      case 23:
        this.sumaPage = 45;
        break;
      case 24:
        this.sumaPage = 46;
        break;
      case 25:
        this.sumaPage = 48;
        break;
      case 26:
        this.sumaPage = 49;
        break;
      case 27:
        this.sumaPage = 50;
        break;
      case 28:
        this.sumaPage = 51;
        break;
      case 29:
        this.sumaPage = 52;
        break;
      case 30:
        this.sumaPage = 55;
        break;
      case 31:
        this.sumaPage = 56;
        break;
      case 32:
        this.sumaPage = 59;
        break;
      case 33:
        this.sumaPage = 64;
        break;
      case 34:
        this.sumaPage = 65;
        break;
      case 35:
        this.sumaPage = 66;
        break;
      case 36:
        this.sumaPage = 67;
        break;
      case 37:
        this.sumaPage = 69;
        break;
      case 38:
        this.sumaPage = 70;
        break;
      case 39:
        this.sumaPage = 71;
        break;
    }
  }

  validarQuestion(data: any, posicion: number) {
    if (data) {
      //localStorage.setItem("m1-p1", "true");
      const pregunta = this.numberModulo.toString() + "-" + posicion.toString();
      localStorage.setItem(pregunta, "true");
      this.sumaPage++;
      this.paginarFull();
      Swal.fire({
        icon: "success",
        title: "¡Super Bien!",
        text: "¡Tu respuesta esta correcta! 🥳",
        confirmButtonText: `¡Avanzar a la siguiente pregunta!`,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "¡No es correcta tu respuesta! 😔  Favor seguir intentando",
        confirmButtonText: `Volver a responder`,
      });
    }
  }

  validShowPregunta(pos) {
    // if (this.page - this.totalPages == pos) {
    //   return true;
    // }
    return false;
  }

  validRespondida(number) {
    const pregunta = this.numberModulo.toString() + "-" + number.toString();
    console.log(pregunta);
    const infoEncryp = localStorage.getItem(pregunta);
    console.log(infoEncryp);
    if (infoEncryp == null || infoEncryp == undefined || infoEncryp == "") {
      return false;
    }
    return true;
  }

  async updateInfoBd(rut: string, modulo: number, pagina: number) {
    await this.trackingService.setAllTracking(rut, modulo, pagina);
  }

  //sath revisar
  validSavePag() {
    var temp = localStorage.getItem("modulo");
    var lastModule = this.cryptoServ.decrypted(temp);
    // var lastModule = localStorage.getItem('modulo');
    var temp = localStorage.getItem("pagina");
    var lastPagina = this.cryptoServ.decrypted(temp);
    // var lastPagina = localStorage.getItem('pagina');
    var temp = localStorage.getItem("rut");
    var rutUser = this.cryptoServ.decrypted(temp);
    // var rutUser = localStorage.getItem('rut');
    if (
      this.numberModulo == parseInt(lastModule) &&
      this.pagina > parseInt(lastPagina)
    ) {
      var encrypt = this.cryptoServ.encrypted(this.pagina);
      localStorage.setItem("pagina", encrypt);
      // localStorage.setItem('pagina', JSON.stringify(this.pagina));
      this.updateInfoBd(rutUser, this.numberModulo, this.pagina);
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this._changeDetectorRef.markForCheck();
  }

  // Método que se llama cuando se renderiza una página
  onPageRendered(event: any) {
    // if (this.page === this.totalPages) {
    //   console.log("Última página del PDF mostrada");
    //   // Aquí puedes ejecutar cualquier lógica adicional que necesites
    // }
  }

  // Método para ir a la página anterior
  prevPage() {
    if (this.sumaPage > 1) {
      this.sumaPage--;
      this.paginarFull();
    }
  }

  nextPage() {
    this.sumaPage++;
    this.paginarFull();
  }

  clean() {
    this.pdfActive = [];
    this.imgActive = [];
    this.preguntaActive = [];
    for (let index = 0; index < 12; index++) {
      this.pdfActive.push(false);
    }
    for (let index = 0; index < 7; index++) {
      this.imgActive.push(false);
    }
    for (let index = 0; index < 7; index++) {
      this.preguntaActive.push(false);
    }
  }

  paginarFull() {
    this.clean();
    switch (true) {
      case this.sumaPage <= 9:
        this.pdfActive[0] = true;
        this.page[0] = this.sumaPage;
        break;
      case this.sumaPage == 10:
        this.imgActive[0] = true;
        break;
      case this.sumaPage <= 14:
        this.pdfActive[1] = true;
        this.page[1] = this.sumaPage - 10;
        break;
      case this.sumaPage == 15:
        this.preguntaActive[0] = true;
        break;
      case this.sumaPage <= 22:
        this.pdfActive[2] = true;
        this.page[2] = this.sumaPage - 15;
        break;
      case this.sumaPage == 23:
        this.imgActive[1] = true;
        break;
      case this.sumaPage <= 31:
        this.pdfActive[3] = true;
        this.page[3] = this.sumaPage - 23;
        break;
      case this.sumaPage <= 40:
        this.pdfActive[4] = true;
        this.page[4] = this.sumaPage - 31;
        break;
      case this.sumaPage == 41:
        this.imgActive[2] = true;
        break;
      case this.sumaPage == 42:
        this.pdfActive[5] = true;
        this.page[5] = this.sumaPage - 41;
        break;
      case this.sumaPage == 43:
        this.preguntaActive[1] = true;
        break;
      case this.sumaPage == 44:
        this.pdfActive[6] = true;
        this.page[6] = this.sumaPage - 43;
        break;
      case this.sumaPage == 45:
        this.imgActive[3] = true;
        break;
      case this.sumaPage <= 51:
        this.pdfActive[7] = true;
        this.page[7] = this.sumaPage - 45;
        break;
      case this.sumaPage == 52:
        this.imgActive[4] = true;
        break;
      case this.sumaPage <= 67:
        this.pdfActive[8] = true;
        this.page[8] = this.sumaPage - 52;
        break;
      case this.sumaPage == 68:
        this.imgActive[5] = true;
        break;
      case this.sumaPage == 69:
        this.preguntaActive[2] = true;
        break;
      case this.sumaPage == 70:
        this.pdfActive[9] = true;
        this.page[9] = this.sumaPage - 69;
        break;
      case this.sumaPage == 71:
        this.preguntaActive[3] = true;
        break;
      case this.sumaPage == 72:
        this.pdfActive[10] = true;
        break;
    }
    console.log(this.pdfActive);
    console.log(this.page);
    console.log(this.imgActive);
  }

  // isPaginaPdf() {
  //   if (this.page <= this.totalPages) {
  //     return true;
  //   }
  //   return false;
  // }

  finModulo() {
    const tempModulo = localStorage.getItem("modulo");
    const lastModule = this.cryptoServ.decrypted(tempModulo);
    const tempRut = localStorage.getItem("rut");
    const rutUser = this.cryptoServ.decrypted(tempRut);
    if (parseInt(lastModule) < this.numberModulo + 1) {
      const newPage = 0;
      const numberModulo = this.numberModulo + 1;
      const encryptModulo = this.cryptoServ.encrypted(numberModulo);
      localStorage.setItem("modulo", encryptModulo);
      const encryptPagina = this.cryptoServ.encrypted(0);
      localStorage.setItem("pagina", encryptPagina);
      this.updateInfoBd(rutUser, numberModulo, newPage);
    }
    this.router.navigate(["/curso"]);
  }
}
