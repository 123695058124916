import { Injectable, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { data } from 'jquery';
//import { ConsoleReporter } from 'jasmine';
//import { Console } from 'console';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { nextTick } from 'process';
import { CryptoservService } from 'src/app/services/cryposerv/cryptoserv.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  infoTrack: string;
  pageTrack: number;
  moduleTrack: number;
  public validOnline: boolean;
  people: Observable<any>;
  constructor(
    private bdFirebase: AngularFirestore,
    private cryptoServ: CryptoservService
  ) { }

  getStatusOnline(): boolean {
    return this.validOnline;
  }

  setStatusOnline(status: boolean): void {
    this.validOnline = status;
  }

  getLocaleStatusOnline(): boolean {
    const temp = localStorage.getItem('online');
    const info = this.cryptoServ.decrypted(temp);
    //var info = localStorage.getItem('online');
    return JSON.parse(JSON.stringify(info));
  }

  setLocaleStatusOnline(status: boolean): void {
    const encryptStatus = this.cryptoServ.encrypted(status);
    localStorage.setItem('online', encryptStatus);
    //localStorage.setItem('online', JSON.stringify(status));
  }

  getTrackingPage(): string {
    return this.infoTrack;
  }

  setTrackingPage(page: string, modulo: number, numero: number): void {
    this.infoTrack = page;
    const encryptModulo = this.cryptoServ.encrypted(modulo);
    localStorage.setItem('modulo', encryptModulo);
    //localStorage.setItem('modulo', JSON.stringify(modulo));
    const encryptPagina = this.cryptoServ.encrypted(numero);
    localStorage.setItem('pagina', encryptPagina);
    //localStorage.setItem('pagina', JSON.stringify(numero));
  }

  async getdbuser(rut: any) {
    const encryptRut = this.cryptoServ.encrypted(rut);
    localStorage.setItem('rut', encryptRut);
    //localStorage.setItem('rut', JSON.stringify(rut));
    var resData = await this.getAllTracking(rut);
    if (this.validOnline) {
      if (resData == null) {
        console.log("cree una persona nuevaaa en la base de datos");
        console.log(resData);
        const encryptPagina = this.cryptoServ.encrypted(1);
        localStorage.setItem('pagina', encryptPagina);
        //localStorage.setItem('pagina', JSON.stringify(1));
        const encryptModulo = this.cryptoServ.encrypted(0);
        localStorage.setItem('modulo', encryptModulo);
        //localStorage.setItem('modulo', JSON.stringify(0));
        this.moduleTrack = 0;
        this.pageTrack = 1;
        this.setAllTracking(rut, 0, 1);
      } else {
        let auxPagina = '';
        let auxModulo = '';
        // tslint:disable-next-line:forin
        for (let key in resData) {
          if (key == 'pagina') {
            auxPagina = resData[key];
            //localStorage.setItem('pagina', JSON.stringify(resData[key]));
          }
          if (key == 'modulo') {
            auxModulo = resData[key];
            //localStorage.setItem('modulo', JSON.stringify(resData[key]));
          }
        }
        if (auxPagina === '' || auxModulo === '') {
          const encryptModulo = this.cryptoServ.encrypted(0);
          localStorage.setItem('modulo', encryptModulo);
          const encryptPagina = this.cryptoServ.encrypted(1);
          localStorage.setItem('pagina', encryptPagina);
        } else {
          const encryptPagina = this.cryptoServ.encrypted(auxPagina);
          localStorage.setItem('pagina', encryptPagina);
          const encryptModulo = this.cryptoServ.encrypted(auxModulo);
          localStorage.setItem('modulo', encryptModulo);
        }
      }
    } else {
      const encryptPagina = this.cryptoServ.encrypted(1);
      localStorage.setItem('pagina', encryptPagina);
      //localStorage.setItem('pagina', JSON.stringify(1));
      const encryptModulo = this.cryptoServ.encrypted(0);
      localStorage.setItem('modulo', encryptModulo);
      //localStorage.setItem('modulo', JSON.stringify(0));
      this.moduleTrack = 0;
      this.pageTrack = 1;
    }
  }

  async getValidDbUser(rut: any) {
    let resultData = false;
    const resData = await this.getAllTracking(rut);
    if (this.validOnline) {
      if (resData != null) {
        // tslint:disable-next-line:forin
        for (var key in resData) {
          if (key == 'pagina') {
            this.pageTrack = resData[key];
            const encryptVPagina = this.cryptoServ.encrypted(resData[key]);
            localStorage.setItem('vpagina', encryptVPagina);
          }
          if (key == 'modulo') {
            this.moduleTrack = resData[key];
            const encryptVModulo = this.cryptoServ.encrypted(resData[key]);
            localStorage.setItem('vmodulo', encryptVModulo);
          }
        }
        resultData = true;
      }
    }
    return resultData;
  }

  async getAllTracking(rut: any) {
    let validOline = false;
    let data;
    let rest;
    try {
      rest = await this.bdFirebase.collection('tracking').doc(rut).ref.get().then(function (doc): object {
        if (doc.exists) {
          data = doc.data();
        } else {
          data = null;
        }
        validOline = true;
        return data;
      }).catch(function (error) {
        data = null;
        validOline = false;
        return data;
      });
    } catch (error) {
    }
    this.setStatusOnline(validOline);
    this.setLocaleStatusOnline(validOline);
    return rest;
  }

  async setAllTracking(rut: any, modulo: number, pagina: number) {
    let data = null;
    try {
      data = this.editInfoBd(rut, modulo, pagina);
      await this.getAllTracking(rut);
    } catch (error) {
    }
    return data;
  }

  async editInfoBd(rut: any, modulo: number, pagina: number) {
    try {
      rut = rut.replace('"', '');
      rut = rut.replace('"', '');
      let rest = await this.bdFirebase.collection('tracking').doc(rut).set({
        modulo: modulo,
        pagina: pagina,
        fecUpdate: new Date()
      }, { merge: true });
      await this.getAllTracking(rut);
      return rest;
    } catch (error) {
      console.log('cai en el segundo error:' + error);
    }
  }

  async getAllExport() {
    let info = [];
    await this.bdFirebase.collection('tracking').get().toPromise()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          info.push(doc);
        });
      });
    return info;
  }

  async holaTracking() {
    console.log("hola desde tracking");
    let info = [];
    let cont = 0;
    await this.bdFirebase.collection('tracking').get().toPromise()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          info.push(doc);
        });
      });
    for (let i = 0; i < info.length; i++) {
      let dataProv = info[i].data();
      let rut = info[i].id.toString();
      let modulo = parseInt(dataProv['modulo']);
      let pagina = parseInt(dataProv['pagina']);

      if (modulo == 0) {
        if (pagina == 1) {
          cont++;
          console.log(rut);
          console.log(modulo);
          console.log(pagina);
          //var infoo = await this.bdFirebase.collection('tracking').doc(rut).delete();
          //console.log(infoo);
        }
      }
    }
    console.log(cont);
    //var infoo = await this.bdFirebase.collection('tracking').doc('143771644').delete();
    //console.log(infoo);
  }
}
